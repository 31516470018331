.ModalDialog {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    min-width: 100%;
    min-height: 200px;
    max-width: 100%;
    transform: translate(-50%, -50%);
    justify-content: center;
   
 
  }
  .WarningCard{
    background: white;
    margin: 0 32px;
    border-radius: 8px;
  }
  .close {
    text-align: right;
    padding-right: 12px;
    padding-top: 10px;
    margin-bottom: -10px;
}
.MaxLimit {
    background: #ffffff;
    border-radius: 4px;
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    &__Logo {
      display: flex;
      justify-content: center;
    }
    &--Bold {
      padding-top: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      /* identical to box height */
  
      text-align: center;
  
      color: rgba(0, 0, 0, 0.87);
    }
    &__Description {
      padding-top: 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      /* or 21px */
  
      text-align: center;
  
      color: rgba(0, 0, 0, 0.6);
    }
    &__okay {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */
      cursor: pointer;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      padding-top: 20px;
      color: #009ae0;
      padding-bottom: 20px;
    }
  }
  