.TestimonialCards {
  padding: 0 0 20px 0;
  margin-bottom: 60px;
  margin-top: 123px;
  &__Heading {
    position: sticky;
    top: -2px;
    background-color: #fff;

    padding: 12px 0;

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 8px;

    z-index: 1;
  }
  &__Container {
    display: grid;

    padding: 0 16px;
  }
}
.NothingToShow{
  display: flex;
    justify-content: center;
    margin-top: 20%;
}
