.Published {
  padding: 16px;
  // margin: 16px;
  width: 100vw;

  &__Top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 12px;

    &--Heading {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      font-feature-settings: 'pnum' on, 'lnum' on;

      /* Black */

      color: #3c4852;
    }
    &--Add {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      display: flex;
      align-items: center;
      text-align: right;

      /* Blue */

      color: #009ae0;
    }
  }
  &__Body {
    height: fit-content;
  }
  &__List {
    overflow-x: hidden;
    overflow-y: visible;
    outline: none;

    position: relative;

    padding-bottom: 12px;
  }
}

.PublishedCard {
  width: 100%;
  padding: 16px 56px 16px 16px;

  background-size: cover;
  background-position: right;

  border-radius: 4px;

  &__Testimonial {
    img {
      // margin-bottom: 8px;
    }

    min-height: 118px;
    max-height: 118px;
    min-width: 296px;
    max-width: 296px;
    // overflow: hidden;
    // text-overflow: ellipsis;

    margin-bottom: 16px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    color: #3c4852;
  }

  &__Details {
    display: flex;
    align-items: center;

    &__Image {
      min-width: 52px;
      max-width: 52px;
      min-height: 52px;
      max-height: 52px;

      border-radius: 50%;

      overflow: hidden;

      display: flex;
      justify-content: center;
      align-items: center;

      margin-right: 8px;

      img {
        width: 100%;
      }
    }

    &__Profile {
      &--Name {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;

        color: rgba(0, 0, 0, 0.87);

        margin-bottom: 4px;
      }

      &--CourseName {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        /* identical to box height */

        color: #666666;

        margin-bottom: 4px;
      }

      &--Achievement {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        /* identical to box height */

        color: #666666;
      }
    }
  }
}

.ProfilePlaceHolder {
  text-transform: uppercase;

  background-color: rgba($color: #000000, $alpha: 0.05);

  //   color: #000000;

  font-size: 32px;
  font-weight: bold;

  width: 52px;
  height: 52px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.flickity-page-dots {
  // position: absolute;
  bottom: 0;
  left: 25%;
  margin: 0 auto;
  margin-top: 20px;
  padding: 0;
  width: 50%;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  align-self: center;

  .is-selected {
    min-width: 8px !important;
    max-width: 8px !important;
    min-height: 8px !important;
    max-height: 8px !important;

    background: #3c4852 !important;
  }

  .dot {
    min-width: 4px;
    max-width: 4px;
    min-height: 4px;
    max-height: 4px;

    background: #d7d7d7;

    border-radius: 50%;

    overflow: hidden;
  }
}

.flickity-button {
  background: #ffffff;
  box-shadow: 0px 1.33333px 2.66667px rgba(172, 173, 175, 0.14),
    0px 2px 2.66667px rgba(172, 173, 175, 0.12),
    0px 0.666667px 3.33333px rgba(172, 173, 175, 0.2);
  border-radius: 50%;
  position: absolute;
  bottom: 6px;
  // top: 8px;
  // z-index: 100;
}
.flickity-button:active {
  box-shadow: none;
}

.flickity-prev-next-button {
  width: 30px;
  height: 30px;
  border-radius: 5px;
}
/* icon color */
.flickity-button-icon {
  fill: #333;
}
/* position outside */
.flickity-prev-next-button.previous {
  left: 0;
}
.flickity-prev-next-button.next {
  right: 0;
}
