.Bottom__Button__Wrapper {
  position: fixed;
  bottom: 0;
  padding: 12px 16px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  width: 100%;
  .Main__Button {
    background: #009ae0;
    border: 1px solid #009ae0;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(51, 204, 102, 0.12);
    border-radius: 4px;
    padding: 13px 0;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    width: 77%;

    /* identical to box height, or 137% */

    text-align: center;

    color: #ffffff;
  }
  .Main__Button.unpublish {
    background: #ff4058;
    border: 1px solid #ff4058;
  }
  .Aux__Button {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
    width: 18%;
  }
  .Main__Button:disabled {
    background: #e5e5e5;
    border: 1px solid #e5e5e5;
  }
}
button {
  border: 0;
}
