
.SearchBox__Wrapper {
  background-color: #f3f3f3;
  padding: 10px 16px;
  min-height: 52px;
  top: 56px;
  position: fixed;
    width: 100%;

}
.SearchBox {
  display: flex;
  background-color: white;
  border-radius: 8px;
  justify-content: center;
  .input-field {
    width: 90%;
  }
  &__Search {
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
}
