
.input-field {
  background-color: white;
  color: #000000;
  font-size: 14px;
  border-radius: 8px;
  height: 47px;
  border: none;
  width: 90%;
  padding: 0 5px 0 13px;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
}
input:-internal-autofill-selected {
  background-color: transparent !important;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}
