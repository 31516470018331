.PreviewCard {
  height: 170px;
  width: 314px;
  max-width: 314px;
  min-height: 170px;
  max-height: 170px;
  margin-bottom: 32px;
  border-radius: 6px;
  background-color: transparent;

  font-variant: normal !important;
}
.PreviewCard__Content {
  width: 314px;
  max-width: 314px;
  height: 170px;
  min-height: 170px;
  max-height: 170px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  border-radius: 6px;
  overflow: hidden;
}
.PreviewCard__Content__Top {
  margin: 6px 12px 0;
  padding: 4px 4px 0;
  max-height: 78px;
  /* background-image: url(../../../resources/images/QuotesMark.png); */
  background-position: top left;
  background-repeat: no-repeat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 21px !important;
  /* or 150% */
  color: #1c2465;
}
.PreviewCard__Content__Bottom {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1.3fr 0.7fr;
  grid-template-columns: 1.3fr 0.7fr;
  position: relative;
}
.PreviewCard__Content__Bottom__Left {
  padding: 10px 16px;
}
.PreviewCard__Content__Bottom__Left--Name {
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 18px !important;
  /* identical to box height */
  color: #1c2465;
  text-transform: uppercase;
  margin-bottom: 4px;
}
.PreviewCard__Content__Bottom__Left--CourseName {
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 16px !important;
  /* or 133% */
  color: #1c2465;
  text-transform: uppercase;
  margin-bottom: 4px;
}
.PreviewCard__Content__Bottom__Left--Achievement {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  font-variant: normal !important;
}
.PreviewCard__Content__Bottom__Left--Achievement img {
  width: 16px;
  height: 16px;
}
.PreviewCard__Content__Bottom__Left--Achievement--Text {
  font-style: normal;
  font-weight: 800;
  font-size: 14px !important;
  line-height: 17px !important;
  /* identical to box height */
  color: #1c2465;
}
.PreviewCard__Content__Bottom__Right {
  position: relative;
}
.PreviewCard__Content__Bottom__Right img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: absolute;
  bottom: -20px;
  right: -20px;
  background-color: rgba(255, 255, 255, 0.4);
  border: 4px solid rgba(0, 0, 0, 0.18);
}
