.Toast__Body {
  background: #e5e5e5;
  border-radius: 32px;
  z-index: 11;
  position: fixed;
  width: 90%;
  top: 80%;
  left: 50%;
  display: flex;
  text-align: center;
  justify-content: center;

  transform: translate(-50%, -80%);
  padding: 2% 4%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  color: #000000;

  mix-blend-mode: normal;
  opacity: 0.87;
  transition: all 0.4s ease-in;
}
