.Form {
  background-color: #1e1c44;
  height: 100vh;
  width: 100%;

  padding: 24px;

  position: relative;
  right: -100vw;
  -webkit-animation: slide 0.3s forwards;
  animation: slide 0.3s forwards;

  &__Entry {
    margin-top: 15vh;

    &--Label {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      /* identical to box height, or 150% */

      // display: flex;
      align-items: center;

      color: #ffffff;

      margin-bottom: 24px;

      span {
        font-weight: normal;
        font-size: 12px;

        margin-left: 4px;
      }
    }

    &--Info {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      /* or 150% */

      display: flex;
      align-items: center;

      color: #ffffff;

      margin-top: -24px;
      margin-bottom: 24px;
    }

    &__Body {
      margin-bottom: 32px;

      &__UploadedImage {
        width: 250px;

        position: relative;

        border: 1px dashed #ffffff;
        border-radius: 4px;
        &--Image {
          width: 100%;
        }
        &--Remove {
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }

      &__ImageArea {
        width: 100%;

        padding: 12px;

        display: flex;
        align-items: center;
        justify-content: center;

        background: #ffffff;
        border: 1px dashed #009ae0;
        border-radius: 4px;

        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */

        text-transform: capitalize;

        color: #009ae0;

        img {
          margin-right: 12px;
        }
      }

      &__InputArea {
        margin-bottom: 12px;
        width: 100%;
        background-color: #ffffff;

        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        padding: 14px 16px;

        box-shadow: 0px 1px 4px rgba(204, 204, 204, 0.25);
        border-radius: 4px;

        textarea {
          font-family: 'Circular Std Book' !important;
          height: 124px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          font-size: 16px !important;
          line-height: 21px !important;
          /* identical to box height, or 131% */

          display: flex !important;
          align-items: center !important;

          color: #333333 !important;

          resize: none;
        }

        &--Input {
          border: none;
          outline: none;

          width: 90%;

          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 21px;
          /* identical to box height, or 131% */

          display: flex;
          align-items: center;

          color: #333333;

          &:focus {
            background-color: transparent;
          }

          &::placeholder {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 21px;
            /* or 105% */

            display: flex;
            align-items: center;

            color: #999999;
          }
        }
      }
      &--Info {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height, or 150% */

        color: #ff4058;
      }
    }

    &--Button {
      width: 146px;
      padding: 12px;

      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      text-align: center;

      color: #ffffff;

      background-color: #009ae0;

      border: none;
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(204, 204, 204, 0.25);
      border-radius: 4px;

      &:disabled {
        background-color: #e5e5e5;
        color: #999999;
      }

      &:active {
        box-shadow: none;
      }
    }
  }

  &--Back {
    position: absolute;
    left: 24px;
    top: 24px;
  }
}

@-webkit-keyframes slide {
  100% {
    right: 0;
  }
}

@keyframes slide {
  100% {
    right: 0;
  }
}

.Skip {
  position: absolute;
  right: 24px;
  top: 24px;

  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}

.Success {
  padding: 120px 24px 24px;

  display: flex;
  flex-direction: column;
  align-items: center;

  &__Done {
    background-color: #009ae0;
    border-radius: 50%;

    width: 120px;
    height: 120px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 32px;
  }
  &__Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    &--Heading {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      /* identical to box height, or 150% */

      display: flex;
      align-items: center;
      text-align: center;

      color: #121212;

      margin-bottom: 16px;
    }
    &--Text {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */

      text-align: center;

      color: #333333;

      max-width: 340px;
    }
  }
}
