.Header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 16px 16px;

  background-color: #009ae0;

  &__Back {
    margin-right: 32px;
  }
  &__Title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height, or 117% */

    // text-align: center;

    color: #ffffff;
  }
}

.ProgressBar {
  display: flex;
  width: 100%;

  background-color: #575574;
  height: 6px;

  position: fixed;
  top: 0;
  left: 0;

  overflow: hidden;
  &__Bar {
    display: flex;

    height: 6px;

    transition: width 0.3s ease-in-out;
  }
}

.LandingTemplate {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 10vh;

  &__Image {
    margin-bottom: 32px;
  }

  &__Text {
    margin-bottom: 32px;
    &--Heading {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      /* identical to box height */

      text-align: center;

      color: #000000;

      margin-bottom: 16px;
    }
    &--SubHeading {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */

      text-align: center;

      color: #333333;
    }
  }
  &__Buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    &--MainButton {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      text-align: center;

      color: #ffffff;

      width: 100%;

      padding: 12px;

      box-shadow: 0px 2px 4px rgba(51, 204, 102, 0.12);
      border-radius: 4px;
      display: flex;
      justify-content: center;

      img {
        margin-right: 16px;
      }
    }
    &--AuxButton {
      box-shadow: 0px 2px 4px rgba(51, 204, 102, 0.12);
      border-radius: 4px;
      padding: 10px;
      margin-left: 22px;
    }
  }
}

.ModalWrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;

  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.6);

  display: flex;
  align-items: flex-end;

  z-index: 10;

  .Modal {
    min-height: 68vh;
    width: 100%;
    background-color: #ffffff;
    max-height: 100%;
    border-radius: 12px 12px 0 0;
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.12);
  }
}

.TestimonialCard {
  padding: 16px;

  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 24px;
  &__Top {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 12px;

    &__Left {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;

      margin-right: 12px;

      img {
        width: 100%;
      }
    }

    &__Right {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &--Name {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */

        color: #000000;

        margin-bottom: 8px;
      }
      &--Course {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;

        color: rgba(0, 0, 0, 0.6);

        margin-bottom: 8px;
      }
      &--Achievement {
        display: flex;
        align-items: center;

        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */

        color: #40846f;

        text-transform: uppercase;

        img {
          margin-right: 8px;
        }
      }
    }
  }
  &__Bottom {
    display: flex;
    align-items: flex-start;
    img {
      margin-right: 10px;
    }
    &--Testimonial {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      /* or 150% */

      color: rgba(0, 0, 0, 0.87);
    }
  }
  &__Checkbox {
    display: flex;
    justify-content: flex-end;
    align-self: baseline;
    justify-self: flex-end;
    margin-left: auto;
  }
}
.TutorCard {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  padding-left: 16px;
  &__Top {
    display: flex;
    &__Left {
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 33px;
      color: #121212;
      display: flex;
      align-items: flex-end;
    }
  }
  &__Bottom {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    /* or 150% */
    margin-right: 32px;
    margin-top: 12px;

    color: #333333;
  }
}
.NotEmpty {
  padding: 0 0;
}
.SuccessModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__img {
    width: 30%;
    margin: auto;
    margin-top: 20%;
  }
  &__Description {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin: 0 24px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 50px;
    color: #333333;
  }
  &__Bold {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 32px;
    color: #121212;
  }
}

.Error {
  width: 100%;
  height: 40vh;

  display: grid;
  place-content: center;

  &__Heading {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    text-align: center;

    color: rgb(255, 98, 98);
  }
  &__Text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */

    text-align: center;

    color: #333333;
  }
}

.loader {
  width: 100%;
  height: 40vh;

  display: grid;
  place-content: center;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #009ae0;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
#LTmodaltype {
  margin: 0 24px;
  margin-bottom: 50px;
}
#LPNoData {
  padding: 0 24px;
  padding-bottom: 30px;
  .LandingTemplate__Image {
    width: 80%;
    margin-top: 27%;
  }
}
