@import './Form/form.scss';

.StudentHome {
  padding: 64px 24px 24px;

  &__Testimonial {
    &--ThankYou {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      /* identical to box height */

      color: #000000;

      margin-bottom: 16px;
    }
  }
}
